import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const bestTradeinPage = ({location}) =>
  (
    <Layout>

      <SEO title="How to get the best trade-in price for your prestige car"
        description="2020 has been a COVID fuelled rollercoaster ride for every industry, and in particular car sales in Australia"
        location={location}
      />

      <PageHeader
        title="How to get the best trade-in price for your prestige car"
        text="We speak to dozens of prestige car owners each week, and a common question is “how do I get the best trade-in price for my car?”"
      />

      <SectionPageMargin>

        <Heading2>Trading in</Heading2>

        <ContentStyle>

          <Paragraph1>
            At Summon, we provide an alternative to a dealer trade-in, but sometimes people need the speed of a trade-in as
            compared to a private sale process which can take a few weeks.
        </Paragraph1>
          <Paragraph1>
            As almost everyone knows, whether your car is an Audi, Porsche, Mercedes, BMW or Goggomobil, selling it to a dealer
            as a trade-in or at a wholesale price usually comes with a significant discount off the “private sale” price of the car.
        </Paragraph1>
          <Paragraph1>
            But, what is the best way to minimise this discount, and get the best possible trade-in price?
        </Paragraph1>

          <Paragraph1>
            First, let’s start by explaining a typical trade-in process, and the journey your prized car will take from
            your driveway to the garage of the next owner. Let’s imagine you are selling a Range Rover Sport;
        </Paragraph1>

          <ol>
            <li>You have decided your next car will be the new Audi Q7. You take your used Range Rover Sport to the Audi dealership and do a trade-in. Great! You drop your Range Rover Sport off, sign the papers, pay the dealer and drive out in your new Q7</li>
            <li>The Audi dealership don’t sell Range Rovers (only Audi’s), and so they sell your car to a wholesaler</li>
            <li>The wholesaler collects the car, but cannot sell directly to the public. They find a willing Land Rover dealership to sell the car to</li>
            <li>This dealership buys the car from the wholesaler, collects it, and puts it on their used car lot</li>
            <li>A buyer sees the car on Carsales.com.au, visits the dealership and purchases it. Now your Range Rover Sport is in the hands of the next lucky owner!</li>
          </ol>

          <Paragraph1>
            There are various combinations of this journey, depending on if the dealership is part of a larger group, whether the wholesaler
            purchases it directly from you at trade-in, etc.
        </Paragraph1>

          <Paragraph1>
            At each stage in the process, the buying dealer, wholesaler and selling dealer incur expenses such as financing the car
            purchase, real estate costs, insurance costs not to mention Government fees (some of which are passed onto the buyer).
         </Paragraph1>

          <Paragraph1>
            It’s no wonder a dealer trade-in price can be 15% - 20% less than a private sale value of the car. It’s generally not because
            the Audi dealership are making a huge profit on your trade-in, moreso that it’s an inefficient process with many steps and expenses.
          </Paragraph1>

          <Paragraph1>
            The best way to maximise your trade-in value (aside from driving a hard bargain!) is to consider how to reduce the number of steps. For example;
          </Paragraph1>

          <ul>
            <li>Buy your Audi from a dealership that is part of a group that also owns Land Rover dealers, may mean the car doesn’t need to go through a wholesaler</li>
            <li>When doing the trade-in, ask if the Audi dealer can sell your car directly to a wholesaler, and not be a step in the middle</li>
            <li>Compare a direct wholesale purchase of your Range Rover as opposed to the dealer trade-in</li>
            <li>Compare selling your car directly to the Land Rover dealership for cash, and then buying the Audi Q7 without a trade-in</li>
          </ul>

          <Paragraph1>
            Alternatively, if you are not in a hurry to sell or buy, consider selling your car privately, and skipping steps 1 through 4!
          </Paragraph1>

          <Paragraph1>
            Summon can help you to sell your car privately, maximising the value of your car and saving you any of the hassle. Enquire for a
            free valuation of your car here.
          </Paragraph1>

        </ContentStyle>

      </SectionPageMargin>

      <ReadyToGetStarted />

    </Layout>

  )

export default bestTradeinPage


const ContentStyle = styled.div`

  li {
    line-height: 2rem;
  }

`;


